import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getCorporateFinancialProductsStart,
  getCorporateFinancialProductsSuccess,
  getCorporateFinancialProductsFailure,
} from "../reducers/getCorporateFinancialProductsReducer";
import axios from "axios";
import Cookies from "js-cookie";

export const getCorporateFinancialProducts = createAsyncThunk(
  "getCorporateFinancialProducts",
  async (param, thunkAPI) => {
    try {
      thunkAPI.dispatch(getCorporateFinancialProductsStart());
      const token = Cookies.get("token");

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}api/Corporate_And_Financial/GetProduct?product_type=${param}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const responseData = await response;

      thunkAPI.dispatch(
        getCorporateFinancialProductsSuccess(responseData?.data?.result)
      );

      return responseData;
    } catch (error) {
      thunkAPI.dispatch(
        getCorporateFinancialProductsFailure(error.response.data.Message)
      );

      throw error;
    }
  }
);
