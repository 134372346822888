import React, { useEffect } from "react";
import { Button, Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getPABillingInfo } from "../../../Redux/actions/getPABillingInfo";

const PAFormFour = ({
  onFinishStep4,
  onFinishFailedFour,
  form,
  handlePrev,
}) => {
  const dispatch = useDispatch();
  const formthreeData = useSelector((state) => state.PAFormThree.data);
  const { data, loading } = useSelector((state) => state.getPABillingInfo);

  useEffect(() => {
    if (formthreeData && formthreeData.Unique_ID) {
      dispatch(getPABillingInfo({ Unique_ID: formthreeData.Unique_ID }));
    }
  }, [dispatch, formthreeData]);

  useEffect(() => {
    if (data && data.length > 0) {
      form.setFieldsValue({
        Gross_Contribution: data[0]?.Gross_Contribution || "",
        Policy_Amount: data[0]?.Policy_Amount || "",
        Delivery_Charges: data[0]?.Delivery_Charges || "",
        Total_Payable: data[0]?.Total_Payable || "",
      });
    }
  }, [form, data, loading]);
  return (
    <div
      className="col-sm-8 mForm"
      style={{
        // backgroundImage: `url(${process.env.REACT_APP_URL_ASSETS}Assets/img/greenCar.png)`,
        // backgroundSize: "cover",
        // backgroundRepeat: "no-repeat",
        backgroundColor: "rgb(23 105 172 / 45%)",
      }}
    >
      <div className="centreWidthFOrm">
        <h3 className="cnhbsh3">Salaam Personal Accident Takaful</h3>
        <h5 className="cnhbsh5"> Checkout Form</h5>
        <Form
          name="sehatFormTwo"
          onFinish={onFinishStep4}
          onFinishFailed={onFinishFailedFour}
          autoComplete="off"
          form={form}
          layout="vertical"
        >
          <div className="row">
            <div className="col-sm-12 labelSSS">
              <Form.Item name="Gross_Contribution" label="Gross Contribution">
                <Input placeholder="Gross Contribution" readOnly />
              </Form.Item>
            </div>
            <div className="col-sm-12 labelSSS">
              <Form.Item name="Policy_Amount" label="Policy Amount">
                <Input placeholder="Policy Amount" readOnly />
              </Form.Item>
            </div>
            <div className="col-sm-12 labelSSS">
              <Form.Item name="Delivery_Charges" label="Delivery Charges">
                <Input placeholder="Delivery Charges" readOnly />
              </Form.Item>
            </div>
            <div className="col-sm-12 labelSSS">
              <Form.Item name="Dicount_Code" label="Discount Code">
                <Input placeholder="Dicount Code" />
              </Form.Item>
            </div>

            <div className="col-sm-12 labelSSS">
              <Form.Item name="Affiliate_Code" label="Affiliate Code">
                <Input placeholder="Affliate Code" />
              </Form.Item>
            </div>
            <div className="col-sm-12 labelSSS">
              <Form.Item name="Total_Payable" label="Total Payable">
                <Input placeholder="Total Payable" readOnly />
              </Form.Item>
            </div>
            <div className="col-sm-6">
              <Form.Item>
                <Button onClick={handlePrev}>Back</Button>
              </Form.Item>
            </div>
            <div className="col-sm-6">
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ background: "#1C75BC" }}
                >
                  Submit
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default PAFormFour;
