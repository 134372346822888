import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  postMessage: null,
  postError: null,
  data: undefined,
};

const postPAFormTwoSlice = createSlice({
  name: "postPAFormTwo",
  initialState,
  reducers: {
    postPAFormTwoStart(state, payload) {
      state.loading = true;
      state.postError = null;
      state.postMessage = null;
    },

    postPAFormTwoSuccess(state, action) {
      state.loading = false;
      state.data = action.payload;
    },
    postPAFormTwoFailure(state, action) {
      state.loading = false;
      state.postError = action.payload;
      state.postMessage = null;
    },

    clearMessage: (state) => {
      state.postError = null;
      state.postMessage = null;
    },
  },
});

export const {
  postPAFormTwoStart,
  postPAFormTwoSuccess,
  postPAFormTwoFailure,
  clearError,
  clearMessage,
} = postPAFormTwoSlice.actions;

export default postPAFormTwoSlice.reducer;
