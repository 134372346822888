import React, { useEffect, useState } from "react";
import { Button, Checkbox, Form, Input, Radio, Select } from "antd";
import { SalamHealthCoverageAPI } from "../../../Data/Data";
import { useDispatch, useSelector } from "react-redux";
import { getHealthPlan } from "../../../Redux/actions/getHealthPlan";

const HealthFormFive = ({
  onFinishStep5,
  product_id,
  DOB,
  form,
  handlePrev,
}) => {
  const dispatch = useDispatch();

  const [selectedMaternityIndex, setSelectedMaternityIndex] = useState(null);
  const handleCheckboxChange = (index) => {
    setSelectedMaternityIndex((prevIndexes) =>
      prevIndexes && prevIndexes.includes(index)
        ? prevIndexes.filter((itemIndex) => itemIndex !== index)
        : [...(prevIndexes || []), index]
    );
  };

  const { data } = useSelector((state) => state.getHealthPlan);
  const handleSubmit = (index) => {
    if (selectedMaternityIndex?.length > 0) {
      const selectedData = data[selectedMaternityIndex];
      if (selectedData) {
        const { Plan_ID } = selectedData;
        const createData = {
          Maternity: "Y",
          Plan_ID,
        };
        onFinishStep5(createData);
      }
    } else {
      const selectedData = data[index];
      if (selectedData) {
        const { Plan_ID } = selectedData;
        const createData = {
          Maternity: "N",
          Plan_ID,
        };
        onFinishStep5(createData);
      }
    }
  };
  useEffect(() => {
    dispatch(getHealthPlan({ product_id, DOB }));
  }, []);

  return (
    <div
      className="col-sm-8 mForm"
      style={{
        // backgroundImage: `url(${process.env.REACT_APP_URL_ASSETS}Assets/img/greenCar.png)`,
        // backgroundSize: "cover",
        // backgroundRepeat: "no-repeat",
        // backgroundColor: '#0FB583'
        backgroundColor: "rgb(15 181 131 / 69%)",
      }}
    >
      <div className="centreWidthFOrm">
        <h3 className="cnhbsh3">Salaam Health Takaful</h3>
        <h5 className="cnhbsh5">Coverage</h5>
        <Form
          name="sehatFormSix"
          onFinish={handleSubmit}
          // onFinishFailed={onFinishFailedFive}
          autoComplete="off"
          form={form}
        >
          <div className="row sehatCrdTakaful">
            {data &&
              data?.map((value, index) => (
                <div className="col-sm-4 sehatTakafulCoverageCard">
                  <div className="xyz">
                    <div className="title">
                      <p>Plan {value?.Plan_Name}</p>
                    </div>
                    <div className="detailsSehatTakaful">
                      <div className="sehatTakDetHead">
                        <p className="heading">Contribution</p>
                        <p className="amount">
                          PKR <span>{value?.Contribution}</span>
                        </p>
                      </div>

                      <div className="sehatTakDetHead">
                        <p className="heading">Hospitalization</p>
                        <p className="amount">
                          PKR <span>{value?.Hos_Max_Limit}</span>
                        </p>
                      </div>

                      <div className="sehatTakDetHead">
                        <p className="heading">Without Medical Care</p>
                        <p className="amount">
                          PKR <span>{value?.WithoutMat}</span>
                        </p>
                      </div>

                      <div className="sehatTakDetHead">
                        <p className="heading">With Medicare</p>
                        <p className="amount">
                          PKR <span>{value?.WithMat}</span>
                        </p>
                      </div>
                    </div>

                    <Form.Item>
                      <Checkbox
                        onChange={() => handleCheckboxChange(index)}
                        checked={selectedMaternityIndex?.includes(index)}
                      >
                        Maternity
                      </Checkbox>
                    </Form.Item>
                    <Form.Item>
                      <Button
                        type="primary"
                        // htmlType="submit"
                        style={{ background: "#1C75BC" }}
                        onClick={() => handleSubmit(index)}
                      >
                        Select
                      </Button>
                    </Form.Item>
                  </div>
                </div>
              ))}
            <div className="col-sm-12">
              <Form.Item>
                <Button onClick={handlePrev}>Back</Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default HealthFormFive;
