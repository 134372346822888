import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  postMessage: null,
  postError: null,
  data: undefined,
};

const postPAFormFourSlice = createSlice({
  name: "postPAFormFour",
  initialState,
  reducers: {
    postPAFormFourStart(state, payload) {
      state.loading = true;
      state.postError = null;
      state.postMessage = null;
    },

    postPAFormFourSuccess(state, action) {
      state.loading = false;
      state.data = action.payload;
      state.postMessage = action.payload.postMessage;
    },

    postPAFormFourFailure(state, action) {
      state.loading = false;
      state.postError = action.payload;
      state.postMessage = null;
    },

    clearMessage: (state) => {
      state.postError = null;
      state.postMessage = null;
    },
  },
});

export const {
  postPAFormFourStart,
  postPAFormFourSuccess,
  postPAFormFourFailure,
  clearError,
  clearMessage,
} = postPAFormFourSlice.actions;

export default postPAFormFourSlice.reducer;
