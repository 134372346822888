import React, { Fragment, useState } from "react";
import Fade from "react-reveal/Fade";
import Flip from "react-reveal/Flip";
import Motortabs from "./Motortabs";
import WhyChoseUsMotors from "../WhychoseUs/WhyChoseUsMotors";
import Productsnav from "./Productsnav";
import FormOne from "./MotorForms/FormOne";

function SalamMotor() {
  const [productHeading, setProductHeading] = useState();
  return (
    <Fragment>
      <Productsnav />

      <div className="container tabss motor">
        <div className="row">
          <div className="col-sm-12 productBanner motorsss salamMotor">
            <Fade up delay={500}>
              <h2 className="oswald"> Salaam Motor Takaful</h2>
              <p className="cemter pb">
                <b>
                  Going through the often confusing and complex options to
                  select the best type of motor cover for your vehicle.
                </b>
              </p>
              <p className="cemter">
                Well, now you don’t have to go anywhere. Salaam Motor Takaful
                cover offers you the best solution as per your needs at a
                minimum price. Allow our professional team to guide you through
                or scroll below for further information.
              </p>
            </Fade>
          </div>
          <div className="col-sm-12 ">
            <Fade left delay={800}>
              <h2 className="oswald"> Coverage</h2>
              <p className="cemter">
                <b>
                  Salaam Motor Takaful policy provides coverage for the
                  following
                </b>
              </p>
            </Fade>
          </div>

          <div className="col-sm-12 heads text">
            <Motortabs setProductHeading={setProductHeading} />
          </div>

          <div className="col-sm-12 motorForm">
            <FormOne productHeading={productHeading} />
          </div>

          <div className="col-sm-12 heads text">
            <WhyChoseUsMotors />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default SalamMotor;
