import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  postPAFormThreeStart,
  postPAFormThreeSuccess,
  postPAFormThreeFailure,
  postPAFormThreeDataSuccess,
} from "../reducers/postPAFormThreeReducer.js";
import axios from "axios";
import Cookies from "js-cookie";

export const postPAFormThree = createAsyncThunk(
  "postPAFormThree",
  async ({ values }, thunkAPI) => {
    try {
      thunkAPI.dispatch(postPAFormThreeStart());
      const token = Cookies.get("token");

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/PA/SavePASecondPage`,
        values,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Set content type if necessary
          },
        }
      );

      const responseData = await response;

      thunkAPI.dispatch(postPAFormThreeDataSuccess(responseData?.data?.result));
      thunkAPI.dispatch(postPAFormThreeSuccess(values));
    } catch (error) {
      thunkAPI.dispatch(postPAFormThreeFailure(error));

      throw error;
    }
  }
);
