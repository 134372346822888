import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getMotorMakeStart,
  getMotorMakeSuccess,
  getMotorMakeFailure,
} from "../reducers/getMotorMakeReducer";
import axios from "axios";
import Cookies from "js-cookie";

export const getMotorMake = createAsyncThunk(
  "getMotorMake",
  async ({ productId }, thunkAPI) => {
    try {
      thunkAPI.dispatch(getMotorMakeStart());
      const token = Cookies.get("token");
      const params = {
        Product_ID: productId,
      };
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}api/Motor/GetMakes`,
        {
          params,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const responseData = await response;

      thunkAPI.dispatch(getMotorMakeSuccess(responseData?.data?.result));

      return responseData;
    } catch (error) {
      thunkAPI.dispatch(getMotorMakeFailure(error.response.data.Message));

      throw error;
    }
  }
);
