import React, { useEffect } from "react";
import { Button, Form, Input, Select, DatePicker } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getLiveIn } from "../../../Redux/actions/getLiveIn";
import { getHomeUnit } from "../../../Redux/actions/getHomeUnit";
import { getHomeOwnerShip } from "../../../Redux/actions/getHomeOwnerShip";
import { getHomeCity } from "../../../Redux/actions/getHomeCity";
import { getHomeOccupation } from "../../../Redux/actions/getHomeOccupation";

const HomeFormFive = ({ onFinishStep5, onFinishFailedFive, handlePrev }) => {
  const { Option } = Select;
  const dispatch = useDispatch();

  const genderData = [
    {
      value: "M",
      title: "Male",
    },
    {
      value: "F",
      title: "Female",
    },
  ];
  useEffect(() => {
    dispatch(getHomeCity());
    dispatch(getHomeOccupation());
  }, [dispatch]);
  return (
    <div
      className="col-sm-8 mForm"
      style={{
        // backgroundImage: `url(${process.env.REACT_APP_URL_ASSETS}Assets/img/greenCar.png)`,
        // backgroundSize: "cover",
        // backgroundRepeat: "no-repeat",
        backgroundColor: "rgb(23 105 172 / 45%)",
      }}
    >
      <div className="centreWidthFOrm">
        <h3 className="cnhbsh3">Salaam Home Takaful</h3>
        <h5 className="cnhbsh5"> Checkout Form</h5>
        <Form
          name="sehatFormTwo"
          onFinish={onFinishStep5}
          onFinishFailed={onFinishFailedFive}
          autoComplete="off"
          layout="vertical"
        >
          <div className="row">
            <div className="col-sm-12 labelSSS">
              <Form.Item name="Dicount_Code" label="Discount Code">
                <Input placeholder="Dicount Code" />
              </Form.Item>
            </div>
            <div className="col-sm-12 labelSSS">
              <Form.Item name="Affiliate_Code" label="Affiliate Code">
                <Input placeholder="Affliate Code" />
              </Form.Item>
            </div>
            <div className="col-sm-6">
              <Button onClick={handlePrev}>Back</Button>
            </div>
            <div className="col-sm-6">
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ background: "#1C75BC" }}
                >
                  Submit
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default HomeFormFive;
