import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  message: null,
  error: null,
  data: [],
};

const getPACitySlice = createSlice({
  name: "getPACity",
  initialState,
  reducers: {
    getPACityStart(state, payload) {
      state.loading = true;
      state.error = null;
      state.message = null;
    },

    getPACitySuccess(state, action) {
      state.loading = false;
      const flattenedArray = []?.concat(...action?.payload);
      state.data = flattenedArray;
      state.message = action.payload.message;
    },
    getPACityFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = null;
    },
    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
});

export const {
  getPACityStart,
  getPACitySuccess,
  getPACityFailure,
  clearError,
  clearMessage,
} = getPACitySlice.actions;

export default getPACitySlice.reducer;
