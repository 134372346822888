import React, { Fragment } from "react";
import { useParams } from "react-router-dom";
import { News } from "../../Data/Data";
import Fade from "react-reveal/Fade";
import Recentnews from "./Recentnews";

function SingleNews() {
  const { Key } = useParams();
  console.log(Key);

  const newsItem = News.find((data) => data.Key.toString() === Key);

  return (
    <Fragment>
      <div className="container governancemain motor">
        <div className="row arrticleAndBlogs">
          <div className="col-sm-12 heads govbanner newss">
            <Fade up delay={500}>
              <h2 className="oswald">News And Announcements</h2>
            </Fade>
          </div>
        </div>

        <div className="row singleblog">
          <div className="col-sm-9 singlenews">
            {newsItem && (
              <div className="container-fluid news" key={newsItem.heading}>
                <div className="container">
                  <div className="row">
                    <div className="col-sm-12 heads">
                      <Fade left delay={500}>
                        <img src={newsItem.feature} alt="img" />
                      </Fade>
                      <Fade left delay={1000}>
                        <h2 className="oswald">{newsItem.heading}</h2>
                      </Fade>
                      {/* {newsItem?.date ? (
                        <Fade left delay={1500}>
                          <p className="date">
                            <b>Date</b>: {newsItem?.date}
                          </p>
                        </Fade>
                      ) : (
                        <></>
                      )} */}
                    </div>
                    <div className="col-sm-12 heads text newsheight">
                      <Fade left delay={2000}>
                        <p>{newsItem.content}</p>
                      </Fade>
                      {newsItem?.content1 && (
                        <Fade left delay={2000}>
                          <p>{newsItem.content1}</p>
                        </Fade>
                      )}
                      {newsItem?.content2 && (
                        <Fade left delay={2000}>
                          <p>{newsItem.content2}</p>
                        </Fade>
                      )}
                      {newsItem?.linkNew ? 
                        <Fade left delay={3000}>
                          <a href={newsItem?.linkNew} target="_blank">{newsItem?.linkOne}</a>
                        </Fade> : null
                      }
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="col-sm-3">
            <Recentnews />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default SingleNews;
