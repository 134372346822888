import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  message: null,
  error: null,
  data: [],
};

const getPARelationSlice = createSlice({
  name: "getPARelation",
  initialState,
  reducers: {
    getPARelationStart(state, payload) {
      state.loading = true;
      state.error = null;
      state.message = null;
    },

    getPARelationSuccess(state, action) {
      state.loading = false;
      const flattenedArray = []?.concat(...action?.payload);
      state.data = flattenedArray;
      state.message = action.payload.message;
    },
    getPARelationFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = null;
    },
    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
});

export const {
  getPARelationStart,
  getPARelationSuccess,
  getPARelationFailure,
  clearError,
  clearMessage,
} = getPARelationSlice.actions;

export default getPARelationSlice.reducer;
