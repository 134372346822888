import React, { useEffect, useState } from "react";
import { Button, Checkbox, Form, Input, Radio, Select } from "antd";
import { SalamHealthCoverageAPI } from "../../../Data/Data";
import { useDispatch, useSelector } from "react-redux";
import { getHealthPlan } from "../../../Redux/actions/getHealthPlan";
import { getHomePlan } from "../../../Redux/actions/getHomePlan";

const HomeFormThree = ({
  onFinishStep3,
  onFinishFailedThree,
  setSelectedPlan,
  selectedPlan,
  handlePrev,
}) => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.getHomePlan);
  const handleClick = (id) => {
    setSelectedPlan(id);
  };
  const handleFinish = () => {
    if (selectedPlan) {
      onFinishStep3(selectedPlan);
    }
  };
  useEffect(() => {
    dispatch(getHomePlan());
  }, []);
  return (
    <div
      className="col-sm-8 mForm"
      style={{ backgroundColor: "rgb(15 181 131 / 69%)" }}
    >
      <div className="centreWidthFOrm">
        <h3 className="cnhbsh3">Salaam Health Takaful</h3>
        <h5 className="cnhbsh5">Coverage</h5>
        <Form
          name="sehatFormSix"
          onFinish={handleFinish}
          onFinishFailed={onFinishFailedThree}
          autoComplete="off"
        >
          <div className="row sehatCrdTakaful">
            {data &&
              data?.map((value, index) => (
                <div className="col-sm-4 sehatTakafulCoverageCard">
                  <div className="xyz">
                    <div className="title">
                      <p>Plan {value?.Coverage}</p>
                    </div>
                    <div className="detailsSehatTakaful">
                      <div className="sehatTakDetHead">
                        <p className="heading">Contribution</p>
                        <p className="amount">
                          PKR <span>{value?.Contribution}</span>
                        </p>
                      </div>

                      <div className="sehatTakDetHead">
                        <p className="heading">Building & Structure</p>
                        <p className="amount">
                          {/* {value?.BuildingAndStructure ? 'PKR ' : null} */}
                          <span>
                            {value?.BuildingAndStructure
                              ? value?.BuildingAndStructure
                              : "--"}
                          </span>
                        </p>
                      </div>

                      <div className="sehatTakDetHead">
                        <p className="heading">Loss Of Rent</p>
                        {/* {value?.LossOfRent ? 'PKR ' : null} */}
                        <p className="amount">
                          <span>
                            {value?.LossOfRent ? value?.LossOfRent : "--"}
                          </span>
                        </p>
                      </div>
                      <div className="sehatTakDetHead">
                        <p className="heading">Content</p>

                        <p className="amount">
                          <span>{value?.Content ? value?.Content : "--"}</span>
                        </p>
                      </div>

                      <div className="sehatTakDetHead">
                        <p className="heading">Cash & Jewelry</p>

                        <p className="amount">
                          <span>{value?.CashAndJewelry}</span>
                        </p>
                      </div>
                      <div className="sehatTakDetHead">
                        <p className="heading">Total Sum Covered</p>

                        <p className="amount">
                          <span>{value?.TotalSumCovered}</span>
                        </p>
                      </div>
                    </div>

                    <Form.Item>
                      <Button
                        type="primary"
                        onClick={() => handleClick(value)}
                        htmlType="submit"
                        style={{ background: "#1C75BC" }}
                      >
                        Select
                      </Button>
                    </Form.Item>
                  </div>
                </div>
              ))}
            <div className="col-sm-12">
              <Button onClick={handlePrev}>Back</Button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default HomeFormThree;
