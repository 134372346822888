import React, { Fragment } from "react";
import Fade from "react-reveal/Fade";
import Engineeringtable from "./Engineeringtable";
import CorProdNav from "./CorProdNav";
import CorporateAndFinancialForm from "../CorporateAndFinancialForms/CorporateAndFinancialForm";

const SalaamEngineering = () => {
  return (
    <Fragment>
      <CorProdNav />
      <div className="container tabss motor">
        <div className="row">
          <div className="col-sm-12 heads productBanner Engineering">
            <Fade left delay={500}>
              <h2 className="oswald"> Salaam Engineering Takaful</h2>
              <p className="cemter">
                With Salaam Engineering Takaful's customizable product set,
                secure your organization’s valuable assets & profit from
                unforeseen circumstances. For further info talk to our
                consultant or scroll below.
              </p>
            </Fade>
          </div>
          <div className="col-sm-12 heads text">
            <Engineeringtable />
          </div>
          <div className="col-sm-12 motorForm">
            <CorporateAndFinancialForm title={"Engineering"} />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SalaamEngineering;
