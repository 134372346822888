import React, { useState } from "react";
import { PlayCircleOutlined } from "@ant-design/icons";
import { Modal } from "antd";

const SalaamAtThumbnail = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <div className="thumbnail-container">
                  <iframe
          // width="500"
          // height="500"
          src="https://www.youtube.com/embed/EEFAxUXqdRU?si=2vVhR3PNdAJkw41V"
          title="YouTube video player"
          frameborder="0"
          className="youtubeele"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
      {/* <img
        src="/gallery/thumbnail.jpg"
        alt="Thumbnail"
        className="thumbnail-image"
      />
      <div>
        <span className="wave"></span>
        <PlayCircleOutlined className="icon" onClick={showModal} />
      </div>
      <Modal
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        centered
        className="videoModal"
      >
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/EEFAxUXqdRU?si=2vVhR3PNdAJkw41V"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
      </Modal> */}
    </div>
  );
};

export default SalaamAtThumbnail;
