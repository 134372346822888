import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  postPAFormTwoStart,
  postPAFormTwoSuccess,
  postPAFormTwoFailure,
} from "../reducers/postPAFormTwoReducer.js";
import Cookies from "js-cookie";
import { getToken } from "./getToken";

export const postPAFormTwo = createAsyncThunk(
  "postPAFormTwo",
  async ({ selectedPlan }, thunkAPI) => {
    try {
      thunkAPI.dispatch(postPAFormTwoStart());

      thunkAPI.dispatch(postPAFormTwoSuccess(selectedPlan));
    } catch (error) {
      thunkAPI.dispatch(postPAFormTwoFailure(error));

      throw error;
    }
  }
);
