import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getPACityStart,
  getPACitySuccess,
  getPACityFailure,
} from "../reducers/getPACityReducer.js";
import axios from "axios";
import Cookies from "js-cookie";

export const getPACity = createAsyncThunk("getPACity", async (_, thunkAPI) => {
  try {
    thunkAPI.dispatch(getPACityStart());
    const token = Cookies.get("token");

    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}api/PA/GetCity`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const responseData = await response;

    thunkAPI.dispatch(getPACitySuccess(responseData?.data?.result));

    return responseData;
  } catch (error) {
    thunkAPI.dispatch(getPACityFailure(error.response.data.Message));

    throw error;
  }
});
