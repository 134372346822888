import React from "react";
import { Button, DatePicker, Form, Input, Select, Radio } from "antd";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMotorMake } from "../../../Redux/actions/getMotorMake";
import { getMotorVehicle } from "../../../Redux/actions/getMotorVehicle";
import { getMotorYear } from "../../../Redux/actions/getMotorYear";
import { getCalculateTracker } from "../../../Redux/actions/getCalculateTracker";

const FormTwo = ({
  onFinishFailedTwo,
  onFinishStep2,
  handlePrev,
  form,
  trackerRequired,
  handleTrackerChange,
  productId,
  product,
}) => {
  const dispatch = useDispatch();
  const { Option } = Select;
  const { data } = useSelector((state) => state.getMotorMake);
  const vehicleData = useSelector((state) => state.getMotorVehicle.data);
  const yearData = useSelector((state) => state.getMotorYear.data);
  const calculateData = useSelector((state) => state.getCalculateTracker.data);
  const [carMake, setMarMake] = useState();
  const [calYear, setCalYear] = useState();
  const [calValue, setCalValue] = useState();
  const handleMakeChange = async (e) => {
    await dispatch(getMotorVehicle({ e, productId }));
    setMarMake(e);
  };
  const handleYearChange = async (e) => {
    setCalYear(e);
  };
  const handleValueChange = async (e) => {
    setCalValue(e.target.value);
  };

  useEffect(() => {
    dispatch(getMotorMake({ productId }));
    dispatch(getMotorYear());
  }, [dispatch]);

  useEffect(() => {
    if (trackerRequired && calValue && calYear) {
      dispatch(
        getCalculateTracker({ carMake, calYear, calValue, trackerRequired })
      );
    }
  }, [trackerRequired]);

  return (
    <div
      className="col-sm-8 mForm"
      style={{
        backgroundImage: `url(${process.env.REACT_APP_URL_ASSETS}Assets/img/greenCar.png)`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="centreWidthFOrm">
        <h3 className="cnhbsh3">
          Salaamti {product === "Motorcycle" ? "Motorcycle" : "Car"} Takaful
        </h3>
        <h5 className="cnhbsh5">
          {product === "Motorcycle" ? "Motorcycle" : "Car"} Details
        </h5>
        <Form
          name="motorFormTwo"
          onFinish={onFinishStep2}
          onFinishFailed={onFinishFailedTwo}
          autoComplete="off"
          layout="vertical"
          form={form}
        >
          <div className="row">
            <div className="col-sm-6 labelSSS">
              <Form.Item
                name="Make"
                label={
                  product === "Motorcycle" ? "Motorcycle Make" : "Car Make"
                }
                rules={[
                  {
                    required: true,
                    message: "Please select Car Make!",
                  },
                ]}
              >
                <Select
                  placeholder={
                    product === "Motorcycle" ? "Motorcycle Make" : "Car Make"
                  }
                  onChange={handleMakeChange}
                  value={carMake}
                >
                  {data?.map((item, i) => (
                    <Option key={i} value={item.make_code}>
                      {item.make_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            <div className="col-sm-6 labelSSS">
              <Form.Item
                name="Model"
                label={
                  product === "Motorcycle" ? "Motorcycle Model" : "Car Model"
                }
                rules={[
                  {
                    required: true,
                    message: "Please select Car Model",
                  },
                ]}
              >
                <Select
                  placeholder={
                    product === "Motorcycle" ? "Motorcycle Model" : "Car Model"
                  }
                  showSearch
                  filterOption={(inputValue, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(inputValue.toLowerCase()) >= 0
                  }
                >
                  {vehicleData?.map((item, i) => (
                    <Option key={i} value={item.Vehicle_code}>
                      {item.Vehicle_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            <div className="col-sm-6 labelSSS">
              <Form.Item
                name="Year"
                label="Manufacturing Year"
                rules={[
                  {
                    required: true,
                    message: "Please select year!",
                  },
                ]}
              >
                <Select
                  placeholder="Year"
                  onChange={handleYearChange}
                  value={calYear}
                >
                  {yearData?.map((item, i) => (
                    <Option key={i} value={item.Year}>
                      {item.Year}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            <div className="col-sm-6 labelSSS">
              <Form.Item
                name="Value"
                label={
                  product === "Motorcycle" ? "Motorcycle Value" : "Car Value"
                }
                rules={[
                  {
                    required: true,
                    pattern: /^[0-9]*$/,
                    message: "Please input a valid value in PKR (numbers only)",
                  },
                ]}
              >
                <Input
                  placeholder="Value in PKR"
                  onChange={handleValueChange}
                  value={calValue}
                />
              </Form.Item>
            </div>
            {product === "Motorcycle" ? (
              <></>
            ) : (
              <div className="col-sm-6 labelSSS">
                <Form.Item
                  name="Tracker_Required"
                  initialValue="N"
                  label="With tracker"
                >
                  <Input placeholder="With Tracker" readOnly />
                  <div className="yesNoBtn">
                    <Radio.Group
                      name="Tracker_Required"
                      buttonStyle="solid"
                      value={trackerRequired} // Set the value of the Radio.Group to state
                      onChange={handleTrackerChange} // Call function to handle change
                    >
                      <Radio.Button value="Y">Yes</Radio.Button>
                      <Radio.Button value="N">No</Radio.Button>
                    </Radio.Group>
                  </div>
                </Form.Item>
              </div>
            )}
            {calculateData &&
              calculateData?.map((item, i) => (
                <div className="col-sm-6 labelSSS">
                  <Form.Item
                    name="Calculated Value"
                    label="Calculated Value"
                    // rules={[
                    //   {
                    //     required: true,
                    //     pattern: /^[0-9]*$/,
                    //     message: "Please input a valid value in PKR (numbers only)",
                    //   },
                    // ]}
                  >
                    <Input
                      className="myPlaceholder"
                      placeholder={item?.Tracker_Amount}
                      readOnly
                    />
                  </Form.Item>
                </div>
              ))}
          </div>

          <div className="row">
            <div className="col-sm-6 baaccck">
              <Form.Item>
                <Button onClick={handlePrev}>Back</Button>
              </Form.Item>
            </div>

            <div className="col-sm-6">
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ background: "#1C75BC" }}
                >
                  Get Coverage
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default FormTwo;
