import { Form, Modal } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getToken } from "../../../Redux/actions/getToken";
import { postMotorOne } from "../../../Redux/actions/postMotorOne";
import { toast } from "react-toastify";
import { clearMessage } from "../../../Redux/reducers/postMotorOneReducer";
import { clearMessage as formTwoClearerror } from "../../../Redux/reducers/postFormTwoReducer";
import { clearMessage as formThreeClearerror } from "../../../Redux/reducers/postFormThreeReducer";
import { clearMessage as formCommClearerror } from "../../../Redux/reducers/postMotorCommercialReducer";
import FormTwo from "./FormTwo";
import { postFormTwo } from "../../../Redux/actions/postFormTwo";
import FormThree from "./FormThree";
import { postFormThree } from "../../../Redux/actions/postFormThree";
import Cookies from "js-cookie";
import { postMotorFour } from "../../../Redux/actions/postMotorFour";
import MotorFormOne from "./MotorFormOne";
import FormFour from "./FormFour";
import ComercialForm from "./ComercialForm";
import { postMotorCommercial } from "../../../Redux/actions/postMotorCommercial";
import moment from "moment/moment";

const FormOne = ({ productHeading }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm(); // Use form instance for resetting
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [stepFourData, setStepFourData] = useState({});

  const [currentStep, setCurrentStep] = useState(1);
  const [product, setProduct] = useState(null);
  const [productId, setProductId] = useState(null);
  const formOneData = useSelector((state) => state.postMotorOne.data);
  const [stepOneData, setStepOneData] = useState({});
  const [stepTwoData, setStepTwoData] = useState({});
  const [stepThreeData, setStepThreeData] = useState({});
  const [trackerRequired, setTrackerRequired] = useState("N");
  const { postError } = useSelector((state) => state.postMotorOne);
  const formTwoError = useSelector((state) => state.postFormTwo.postError);
  const formThreeError = useSelector((state) => state.postFormThree.postError);
  const formCommError = useSelector(
    (state) => state.postMotorCommercial.postError
  );

  const handleNext = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handlePrev = () => {
    setCurrentStep(currentStep - 1);
  };

  const onFinishStep1 = async (values) => {
    // console.log("Step 1 values:", values);
    setStepOneData(values);

    const resultAction = await dispatch(postMotorOne({ values }));
    if (postMotorOne.fulfilled.match(resultAction)) {
      handleNext();
      // toast.success("Form Submit Successfully");
      // form.resetFields();
    }
  };
  const onFinishStep2 = async (values) => {
    // console.log("Step 1 values:", values);
    setStepTwoData(values);
    values.Tracker_Required = values.Tracker_Required
      ? values.Tracker_Required
      : "N";

    values.Unique_ID = formOneData[0];
    const resultAction = await dispatch(postFormTwo({ values }));
    if (postFormTwo.fulfilled.match(resultAction)) {
      handleNext();
    }
  };
  const onFinishStepCommercial = async (values) => {
    // console.log("Step 1 values:", values);
    setStepTwoData(values);
    values.CProductID = productId;
    const resultAction = await dispatch(postMotorCommercial({ values }));
    if (postMotorCommercial.fulfilled.match(resultAction)) {
      setCurrentStep(1);
      toast.success("Form Submit Successfully");
      form.resetFields();
      setStepTwoData(null);
      setStepOneData(null);
    }
  };
  const onFinishFailedCommercial = async (errorInfo) => {
    toast.error(errorInfo);
  };

  const onFinishStep3 = async (values) => {
    // console.log("Step 1 values:", values);
    setStepThreeData(values);

    values.Unique_ID = formOneData[0];

    const formattedValues = {
      ...values,
      DOB: moment(values.DOB).format("DD-MMM-YYYY"),
      CNIC_Expiry_Date: moment(values.CNIC_Expiry_Date).format("DD-MMM-YYYY"),
    };

    const resultAction = await dispatch(
      postFormThree({ values: formattedValues })
    );
    if (postFormThree.fulfilled.match(resultAction)) {
      handleNext();
    }
  };

  const onFinishStep4 = async (values) => {
    // setStepFourData(values);
    values.Unique_ID = formOneData && formOneData[0];
    values.Day = values.Day.format("DD-MMM-YYYY");
    const resultAction = await dispatch(postMotorFour({ values }));

    if (postMotorFour.fulfilled.match(resultAction)) {
      setIsModalVisible(true);

      setCurrentStep(1);
      Cookies.remove("token");
      form.resetFields();
      setStepOneData(null);
      setStepTwoData(null);
      setStepThreeData(null);
      setStepFourData(null);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinishFailedTwo = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinishFailedThree = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinishFailedFour = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const token = Cookies.get("token");

  useEffect(() => {
    if (!token) {
      dispatch(getToken());
    }
  }, [dispatch]);

  useEffect(() => {
    if (postError) {
      toast.error(postError);
      dispatch(clearMessage());
    }
    if (formTwoError) {
      toast.error(formTwoError);
      dispatch(formTwoClearerror());
    }
    if (formThreeError) {
      toast.error(formThreeError);
      dispatch(formThreeClearerror());
    }
    if (formCommError) {
      toast.error(formCommError);
      dispatch(formCommClearerror());
    }
  }, [postError, formTwoError, formThreeError, formCommError]);

  const handleTrackerChange = (e) => {
    setTrackerRequired(e.target.value);
  };
  const handleModalOk = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (currentStep === 1 && stepOneData) {
      form.setFieldsValue(stepOneData);
    }
    if (currentStep === 2 && stepTwoData) {
      form.setFieldsValue(stepTwoData);
    }
    if (currentStep === 3 && stepThreeData) {
      form.setFieldsValue(stepThreeData);
    }
    // if (currentStep === 4 && stepFourData) {
    //   form.setFieldsValue(stepFourData);
    // }
    // if (currentStep === 5 && stepFiveData) {
    //   form.setFieldsValue(stepFiveData);
    // }
  }, [currentStep, form, stepOneData, stepTwoData, stepThreeData]);
  return (
    <Fragment>
      <div className="container-fluid motorFomrOne">
        <div className="row formTakafulll">
          {/* Form 1 */}

          {currentStep === 1 && (
            <MotorFormOne
              setProductId={setProductId}
              productId={productId}
              product={product}
              setProduct={setProduct}
              onFinishStep1={onFinishStep1}
              onFinishFailed={onFinishFailed}
              form={form}
              productHeading={productHeading}
            />
          )}

          {/* Form 2 */}

          {product == "Commercial vehicle" ? (
            <>
              {currentStep === 2 && (
                <>
                  <ComercialForm
                    onFinishStepCommercial={onFinishStepCommercial}
                    onFinishFailedCommercial={onFinishFailedCommercial}
                    handlePrev={handlePrev}
                    form={form}
                  />
                </>
              )}
            </>
          ) : (
            <>
              {currentStep === 2 && (
                <>
                  <FormTwo
                    onFinishStep2={onFinishStep2}
                    onFinishFailedTwo={onFinishFailedTwo}
                    handlePrev={handlePrev}
                    form={form}
                    trackerRequired={trackerRequired}
                    handleTrackerChange={handleTrackerChange}
                    productId={productId}
                    product={product}
                  />
                </>
              )}

              {/* Form 3 */}

              {currentStep === 3 && (
                <>
                  <FormThree
                    form={form}
                    onFinishStep3={onFinishStep3}
                    onFinishFailedThree={onFinishFailedThree}
                    handlePrev={handlePrev}
                    stepThreeData={stepThreeData}
                  />
                </>
              )}

              {currentStep === 4 && (
                <>
                  <FormFour
                    formOneData={formOneData}
                    form={form}
                    onFinishStep4={onFinishStep4}
                    onFinishFailedFour={onFinishFailedFour}
                    handlePrev={handlePrev}
                  />
                </>
              )}
            </>
          )}

          <div className="col-sm-4 mForm">
            <div>
              <img
                src={`${process.env.REACT_APP_URL_ASSETS}Assets/img/car.png`}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        title="Thank You!"
        open={isModalVisible}
        onOk={handleModalOk}
        centered
        footer={[
          <button
            key="ok"
            onClick={handleModalOk}
            className="btn btn-primary"
            style={{ background: "#1C75BC" }}
          >
            OK
          </button>,
        ]}
      >
        <p style={{ fontSize: "17px" }}>
          One of our representatives will get in touch with you soon.
        </p>
      </Modal>
    </Fragment>
  );
};

export default FormOne;
