import React, { Fragment } from "react";
import Fade from "react-reveal/Fade";
import Flip from "react-reveal/Flip";
import CorProdnav from "./CorProdNav";
import Whychosegrop from "../WhychoseUs/Whychosegrop";
import SalamHealthTab from "./SalamHealthTab";
import CorporateAndFinancialForm from "../CorporateAndFinancialForms/CorporateAndFinancialForm";

const SalaamHealth = () => {
  return (
    <Fragment>
      <CorProdnav />
      <div className="container tabss motor">
        <div className="row">
          <div className="col-sm-12 heads grop productBanner GroupHealthTakaful">
            <Fade left delay={500}>
              <h2 className="oswald">Salaam Group Health Takaful</h2>
              <p className="cemter">
                <b>
                  Employees want to be part of an organization with purposeful
                  direction. These are the individuals who make the best
                  addition to any team.
                </b>
              </p>
              <p>
                Employees play an instrumental role in keeping the workplace
                culture alive and thriving through regular interactions with
                coworkers and leadership. Successful organizations are built
                around employee feedback, ranging from specific medical benefits
                they find most valuable and want. Strong medical, ancillary, and
                wellness benefits help to keep a workforce healthy, reduce
                absenteeism and boost overall productivity. Moreover, providing
                Salaam Health Takaful to your team and allowing employees to
                visit doctors when they are sick is the right thing to do.
              </p>
            </Fade>
          </div>
          <div className="col-sm-12 heads text">
            <SalamHealthTab />
          </div>
          <div className="col-sm-12 motorForm">
            <CorporateAndFinancialForm title={"Group Health"} />
          </div>
        </div>
      </div>

      <Whychosegrop />
    </Fragment>
  );
};

export default SalaamHealth;
