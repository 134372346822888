import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  postHealthTwoStart,
  postHealthTwoSuccess,
  postHealthTwoFailure,
} from "../reducers/postHealthTwoReducer";
import axios from "axios";
import Cookies from "js-cookie";
import { getToken } from "./getToken";

export const postHealthTwo = createAsyncThunk(
  "postHealthTwo",
  async ({ values }, thunkAPI) => {
    try {
      thunkAPI.dispatch(postHealthTwoStart());
      const token = Cookies.get("token");

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/Health/SaveHealthSecondPage`,
        values,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const responseData = await response;

      thunkAPI.dispatch(postHealthTwoSuccess(values));

      return responseData;
    } catch (error) {
      thunkAPI.dispatch(postHealthTwoFailure(error.response.data.Message));

      if (error.response.data.Message) {
        thunkAPI.dispatch(postHealthTwoFailure(error.response.data.Message));
      } else {
        thunkAPI.dispatch(postHealthTwoFailure(error.response.data.status));
      }
      if (error.response && error.response.status === 401) {
        Cookies.remove("token");
        thunkAPI.rejectWithValue({ unauthorized: true });
        await thunkAPI.dispatch(getToken());
      }
      throw error;
    }
  }
);
