import React, { useEffect } from "react";
import { Button, Form, Input, Select, DatePicker } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getPACity } from "../../../Redux/actions/getPACity";
import { getPAOccupation } from "../../../Redux/actions/getPAOccupation";
import { getPARelation } from "../../../Redux/actions/getPARelation";
import moment from "moment";

const PAFormThree = ({
  onFinishStep3,
  onFinishFailedThree,
  handlePrev,
  form,
}) => {
  const { Option } = Select;
  const dispatch = useDispatch();
  const cityData = useSelector((state) => state.getPACity.data);
  const occupationData = useSelector((state) => state.getPAOccupation.data);
  const relationData = useSelector((state) => state.getPARelation.data);

  const genderData = [
    {
      value: "M",
      title: "Male",
    },
    {
      value: "F",
      title: "Female",
    },
  ];
  useEffect(() => {
    dispatch(getPACity());
    dispatch(getPAOccupation());
    dispatch(getPARelation());
  }, []);
  return (
    <div
      className="col-sm-8 mForm"
      style={{
        // backgroundImage: `url(${process.env.REACT_APP_URL_ASSETS}Assets/img/greenCar.png)`,
        // backgroundSize: "cover",
        // backgroundRepeat: "no-repeat",
        backgroundColor: "rgb(23 105 172 / 45%)",
      }}
    >
      <div className="centreWidthFOrm">
        <h3 className="cnhbsh3">Salaam Personal Accident Takaful</h3>
        <h5 className="cnhbsh5">Personal Information</h5>
        <Form
          name="sehatFormTwo"
          onFinish={onFinishStep3}
          onFinishFailed={onFinishFailedThree}
          autoComplete="off"
          layout="vertical"
          form={form}
        >
          <div className="row">
            <div className="col-sm-6 labelSSS">
              <Form.Item
                name="CNIC"
                label="CNIC Number"
                rules={[
                  {
                    required: true,
                    pattern: /^[0-9]{5}-[0-9]{7}-[0-9]{1}$/,
                    message:
                      "Please enter a valid CNIC number in the format XXXXX-XXXXXXX-X",
                  },
                ]}
                normalize={(value) => {
                  if (!value) {
                    return value;
                  }

                  const onlyNums = value.replace(/[^\d]/g, "");
                  if (onlyNums.length <= 5) {
                    return onlyNums.slice(0, 5);
                  }
                  if (onlyNums.length <= 12) {
                    return `${onlyNums.slice(0, 5)}-${onlyNums.slice(5, 12)}`;
                  }
                  return `${onlyNums.slice(0, 5)}-${onlyNums.slice(
                    5,
                    12
                  )}-${onlyNums.slice(12, 13)}`;
                }}
              >
                <Input placeholder="CNIC #" />
              </Form.Item>
            </div>

            <div className="col-sm-6 labelSSS">
              <Form.Item
                name="CNIC_Expiry_Date"
                label="CNIC Expiry Date"
                rules={[
                  {
                    required: true,
                    message: "CNIC Expiry Date is required!",
                  },
                ]}
              >
                <DatePicker
                  placeholder="CNIC Expiry"
                  disabledDate={(current) => {
                    // Disable all dates up to today
                    return current && current < moment().startOf("day");
                  }}
                />
              </Form.Item>
            </div>

            <div className="col-sm-6 labelSSS">
              <Form.Item
                name="DOB"
                label="Date Of Birth"
                rules={[
                  {
                    required: true,
                    message: "DOB is requied!",
                  },
                ]}
              >
                <DatePicker
                  placeholder="DOB"
                  disabledDate={(current) => current && current > new Date()}
                />
              </Form.Item>
            </div>
            <div className="col-sm-6 labelSSS">
              <Form.Item
                name="City"
                label="City"
                rules={[
                  {
                    required: true,
                    message: "City is required!",
                  },
                ]}
              >
                <Select
                  placeholder="City"
                  showSearch
                  filterOption={(inputValue, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(inputValue.toLowerCase()) >= 0
                  }
                >
                  {cityData?.map((item, i) => (
                    <Option value={item?.city_code}>{item?.city_name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            <div className="col-sm-12 labelSSS">
              <Form.Item
                name="Address"
                label="Address"
                rules={[
                  {
                    required: true,
                    message: "Address is required!",
                  },
                ]}
              >
                <Input placeholder="Address" />
              </Form.Item>
            </div>

            <div className="col-sm-6 labelSSS">
              <Form.Item
                name="Gender"
                label="Gender"
                rules={[
                  {
                    required: true,
                    message: "Gender is required!",
                  },
                ]}
              >
                <Select placeholder="Gender">
                  {genderData.map((item, i) => (
                    <Select.Option value={item.value}>
                      {item.title}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            <div className="col-sm-6 labelSSS">
              <Form.Item
                name="Occupation"
                label="Occupation"
                rules={[
                  {
                    required: true,
                    message: "Occupation is required!",
                  },
                ]}
              >
                <Select
                  placeholder="Occupation"
                  showSearch
                  filterOption={(inputValue, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(inputValue.toLowerCase()) >= 0
                  }
                >
                  {occupationData?.map((item, i) => (
                    <Option key={i} value={item?.Occupation_code}>
                      {item?.Occupation_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            <div className="col-sm-6 labelSSS">
              <Form.Item
                name="Beneficiary_RELATION"
                label="Beneficiary Relation"
                rules={[
                  {
                    required: true,
                    message: "Beneficiary Relation is required!",
                  },
                ]}
              >
                <Select placeholder="Beneficiary Relation">
                  {relationData?.map((item, i) => (
                    <Option key={i} value={item?.ID}>
                      {item?.Relation}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className="col-sm-6 labelSSS">
              <Form.Item
                name="Beneficiary_Name"
                label="Beneficiary Name"
                rules={[
                  {
                    required: true,
                    message: "Beneficiary Name is required!",
                  },
                ]}
              >
                <Input placeholder="Beneficiary Name" />
              </Form.Item>
            </div>
            <div className="col-sm-6 labelSSS">
              <Form.Item
                name="Beneficiary_Contact"
                label="Beneficiary Contact"
                rules={[
                  {
                    required: true,
                    pattern: /^03\d{2}-\d{7}$/,
                    message:
                      "Beneficiary Contact number must be in this format 03XX-XXXXXXX",
                  },
                ]}
                normalize={(value) => {
                  if (!value) {
                    return value;
                  }
                  const onlyNums = value.replace(/[^\d]/g, "");
                  if (onlyNums.length <= 4) {
                    return onlyNums;
                  } else if (onlyNums.length <= 11) {
                    return `${onlyNums.slice(0, 4)}-${onlyNums.slice(4, 11)}`;
                  }
                  return `${onlyNums.slice(0, 4)}-${onlyNums.slice(4, 11)}`;
                }}
              >
                <Input placeholder="Beneficiary Contact 03XX-XXXXXXX" />
              </Form.Item>
            </div>
            <div className="col-sm-6 labelSSS">
              <Form.Item
                name="Beneficiary_CNIC"
                label="Beneficiary CNIC Number"
                rules={[
                  {
                    required: true,
                    pattern: /^[0-9]{5}-[0-9]{7}-[0-9]{1}$/,
                    message:
                      "Please enter a valid CNIC number in the format XXXXX-XXXXXXX-X",
                  },
                ]}
                normalize={(value) => {
                  if (!value) {
                    return value;
                  }

                  const onlyNums = value.replace(/[^\d]/g, "");
                  if (onlyNums.length <= 5) {
                    return onlyNums.slice(0, 5);
                  }
                  if (onlyNums.length <= 12) {
                    return `${onlyNums.slice(0, 5)}-${onlyNums.slice(5, 12)}`;
                  }
                  return `${onlyNums.slice(0, 5)}-${onlyNums.slice(
                    5,
                    12
                  )}-${onlyNums.slice(12, 13)}`;
                }}
              >
                <Input placeholder="Beneficiary CNIC #" />
              </Form.Item>
            </div>
            <div className="col-sm-6">
              <Form.Item>
                <Button onClick={handlePrev}>Back</Button>
              </Form.Item>
            </div>
            <div className="col-sm-6">
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ background: "#1C75BC" }}
                >
                  Next
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default PAFormThree;
