import React, { Fragment } from "react";
import Flip from "react-reveal/Flip";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";
import qqrimg from '../../Assets/QRCode.png'

function Appsdownload() {
  const Mobileapps = `${process.env.REACT_APP_URL_ASSETS}Assets/img/mobilsapps.png`
  const Check = `${process.env.REACT_APP_URL_ASSETS}Assets/img/checks.png`
  const android = `${process.env.REACT_APP_URL_ASSETS}Assets/img/playstore.png`
  const apple = `${process.env.REACT_APP_URL_ASSETS}Assets/img/appstore.png`
  return (
    <Fragment>
      <div className="container donwloadapps">
        <div className="row">
          <div className="col-sm-6">
            <Fade top delay={400}>
              <h2>Salaam App, an Insurtech Ecosystem</h2>
            </Fade>
            <Fade top delay={600}>
              {" "}
              <p>
              Get access to our multi-functional Salaam App and avail all the benefits including:
              </p>
            </Fade>
            <ul>
              <Flip top delay={800}>
                {" "}
                <li>
                  <img src={Check} alt="" />	Real Time Claims Monitoring & Intimation
                </li>
              </Flip>
              <Flip top delay={1000}>
                {" "}
                <li>
                  <img src={Check} alt="" />  Free audio and video OPD consultation with qualified doctors
                </li>
              </Flip>
              <Flip top delay={1200}>
                {" "}
                <li>
                  <img src={Check} alt="" /> 	Nearby doctors, laboratories, hospitals, clinics, and other medical facilities
                </li>
              </Flip>
  
              <Flip top delay={1400}>
                {" "}
                <li>
                  <img src={Check} alt="" />  Amazing discounts at laboratories, diagnostic centers, and allied pharmacies
                </li>
              </Flip>

              <Flip top delay={1600}>
                {" "}
                <li>
                  <img src={Check} alt="" />  Enjoy buy 1 get 1 free offer at 1200 merchants across Pakistan, and much more!
                </li>
              </Flip>
            </ul>
           
            <div className="button-container app-buttons">
              <div className="outsideMain">
                <Flip top delay={1000}>
                  <Link to="https://play.google.com/store/apps/details?id=com.avolox.salaamtakaful&hl=en&gl=US&pli=1" target="_blank">
                    <img src={android} alt='android'/>
                  </Link>
                </Flip>
                <Flip top delay={1400}>
                  <Link to="https://apps.apple.com/us/app/salaam-takaful/id1570263059" target="_blank">
                  <img src={apple} alt='apple'/>
                  </Link>
                </Flip>
              </div>
              <div className="qrImage">
                <Flip top delay={1800}>
                  <img src={qqrimg} alt='apple'/>
                </Flip>
            </div>
            </div>  
          </div>
          <div className="col-sm-6 mobiles">
            <Fade down delay={1400}>
              {" "}
              <img src={Mobileapps} alt="apps" />
            </Fade>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Appsdownload;
