import React, { Fragment } from "react";
import Fade from "react-reveal/Fade";
import FinProdNav from "./FinProdNav";
import Bankertabs from "./Bankertabs";
import CorporateAndFinancialForm from "../CorporateAndFinancialForms/CorporateAndFinancialForm";

const SalaamBanker = () => {
  return (
    <Fragment>
      <FinProdNav />
      <div className="container tabss motor">
        <div className="row">
          <div className="col-sm-12 heads productBanner BankersBlanket">
            <Fade left delay={500}>
              <h2 className="oswald">Salaam Bankers Blanket Takaful</h2>
              <p className="cemter">
                {" "}
                This policy is designed to provide package cover for the banks
                and financial Institutions. It covers indemnity to participant’s
                employees, property on premises, property in transit, forged
                cheques, damage to office and contents, and counterfeited
                currency.
              </p>
            </Fade>
          </div>

          <div className="col-sm-12 heads text">
            <Bankertabs />
          </div>
          <div className="col-sm-12 motorForm">
            <CorporateAndFinancialForm title={"Bankers Blanket"} />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SalaamBanker;
