import React, { Fragment } from "react";
import Fade from "react-reveal/Fade";
import MiscelTable from "./MiscelTable";
import CorProdNav from "./CorProdNav";
import CorporateAndFinancialForm from "../CorporateAndFinancialForms/CorporateAndFinancialForm";

const SalaamMiscellaneous = () => {
  return (
    <Fragment>
      <CorProdNav />
      <div className="container tabss motor ">
        <div className="row">
          <div className="col-sm-12 heads productBanner Miscellaneous">
            <Fade left delay={500}>
              <h2 className="oswald"> Salaam Miscellaneous Takaful</h2>
              <p className="cemter">
                <b>
                  {" "}
                  Under Our Miscellaneous Takaful product set, corporates can
                  select from various solutions such as
                </b>
                <br />
                Burglary Takaful, Plate Glass Takaful, Product Liability etc.
                For further info talk to our consultant or scroll below
              </p>
            </Fade>
          </div>
          <div className="col-sm-12 heads text">
            <MiscelTable />
          </div>
          <div className="col-sm-12 motorForm">
            <CorporateAndFinancialForm title={"Miscellaneous"} />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SalaamMiscellaneous;
