import React, { Fragment } from "react";
import Tab from "react-bootstrap/Tab";
import { Sehat } from "../../Data/Data";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";

import Fade from "react-reveal/Fade";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/swiper-bundle.css";

function Sehattabs({ setProductHeading }) {
  const handleSlideClick = (swiper) => {
    const activeIndex = swiper.clickedIndex;
    swiper.slideTo(activeIndex);
  };

  return (
    <Fragment>
      <Tab.Container id="left-tabs-example" defaultActiveKey="1">
        <Row>
          <Col sm={12}>
            <Nav variant="pills" className="">
              <Swiper
                slidesPerView={3}
                pagination={{ clickable: true }}
                spaceBetween={20}
                updateOnWindowResize={true}
                loop={true}
                navigation={{ clickable: true }}
                modules={[Navigation]}
                onSwiper={(swiper) => {
                  swiper.on("click", handleSlideClick);
                }}
                breakpoints={{
                  "@0.00": { slidesPerView: 1, spaceBetween: 10 }, // For very small screens
                  "@0.50": { slidesPerView: 1, spaceBetween: 20 }, // For small screens
                  "@0.75": { slidesPerView: 2, spaceBetween: 20 }, // For foldable phones and iPads
                  "@1.00": { slidesPerView: 3, spaceBetween: 30 }, // For medium screens
                  "@1.50": { slidesPerView: 3, spaceBetween: 30 }, // For larger screens
                }}
                className="mySwiper motorSwiiperrr"
              >
                {Sehat.map((item) => {
                  return (
                    <SwiperSlide>
                      <Nav.Item>
                        <Fade down delay={500}>
                          <Nav.Link
                            eventKey={item.key}
                            onClick={() => setProductHeading(item.setheading)}
                          >
                            <img src={item.image} alt="tabs" />
                            <h4>{item.mainheading}</h4>
                          </Nav.Link>
                        </Fade>
                      </Nav.Item>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </Nav>
          </Col>

          <Col sm={12}>
            <Tab.Content>
              {Sehat.map((item) => {
                return (
                  <Tab.Pane eventKey={item.key}>
                    <div
                      className="row tabs"
                      style={{ backgroundImage: `url(${item.coverImage})` }}
                    >
                      <Fade up delay={500}>
                        <div className="col-sm-4">
                          <div className="mycontents">
                            <div
                              className="row"
                              data-aos="fade-right"
                              data-aos-duration="4000"
                              data-aos-easing="ease-out-cubic"
                            >
                              <div className="col-sm-12">
                                <p
                                  data-aos="fade-right"
                                  data-aos-duration="4000"
                                  data-aos-easing="ease-out-cubic"
                                >
                                  {item.content}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Fade>

                      <Fade up delay={500}>
                        <div className="col-sm-4">
                          <div
                            className="list-detail"
                            data-aos="fade-right"
                            data-aos-duration="4000"
                            data-aos-easing="ease-out-cubic"
                          >
                            {item.heading1 ? (
                              <h3
                                data-aos="fade-right"
                                data-aos-duration="4000"
                                data-aos-easing="ease-out-cubic"
                              >
                                {item.heading1}
                              </h3>
                            ) : (
                              <></>
                            )}
                            <ul>
                              {item.Coverages.map((datas) => {
                                return (
                                  <> {datas.li ? <li>{datas.li}</li> : <></>}</>
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                      </Fade>
                    </div>
                  </Tab.Pane>
                );
              })}
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </Fragment>
  );
}

export default Sehattabs;
