import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  message: null,
  error: null,
  data: [],
};

const getcorpFinanceCitySlice = createSlice({
  name: "getcorpFinanceCity",
  initialState,
  reducers: {
    getcorpFinanceCityStart(state, payload) {
      state.loading = true;
      state.error = null;
      state.message = null;
    },

    getcorpFinanceCitySuccess(state, action) {
      state.loading = false;
      const flattenedArray = []?.concat(...action?.payload);
      state.data = flattenedArray;
      state.message = action.payload.message;
    },
    getcorpFinanceCityFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = null;
    },
    clearError: (state) => {
      state.error = null;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
});

export const {
  getcorpFinanceCityStart,
  getcorpFinanceCitySuccess,
  getcorpFinanceCityFailure,
  clearError,
  clearMessage,
} = getcorpFinanceCitySlice.actions;

export default getcorpFinanceCitySlice.reducer;
