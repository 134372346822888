import React, { useEffect } from "react";
import { Button, DatePicker, Form, Input, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getMotorCity } from "../../../Redux/actions/getMotorCity";
import { getMotorCC } from "../../../Redux/actions/getMotorCC";
import { getMotorColor } from "../../../Redux/actions/getMotorColor";
import dayjs from "dayjs";

const FormThree = ({ onFinishFailedThree, onFinishStep3, handlePrev, form,stepThreeData }) => {
  const { data } = useSelector((state) => state.getMotorCity);
  const ccData = useSelector((state) => state.getMotorCC.data);
  const colorData = useSelector((state) => state.getMotorColor.data);
  const dispatch = useDispatch();
  console.log(ccData, "<====ccData");

  const { Option } = Select;
  
  useEffect(() => {
    dispatch(getMotorCity());
    dispatch(getMotorCC());
    dispatch(getMotorColor());
  }, [dispatch]);

  useEffect(() => {
    form.setFieldsValue({
      CNIC_Expiry_Date: stepThreeData?.CNIC_Expiry_Date
        ? dayjs(stepThreeData.CNIC_Expiry_Date, "YYYY-MM-DD")
        : null,
      DOB: stepThreeData?.DOB
        ? dayjs(stepThreeData.DOB, "YYYY-MM-DD")
        : null,
    });
  }, [stepThreeData]);
  return (
    <div
      className="col-sm-8 mForm"
      style={{
        backgroundImage: `url(${process.env.REACT_APP_URL_ASSETS}Assets/img/blueCar.png)`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="centreWidthFOrm">
        <h3 className="cnhbsh3">Salaam Motor Takaful</h3>
        <h5 className="cnhbsh5">Car Details</h5>
        <Form
          name="motorFormThree"
          onFinish={onFinishStep3}
          onFinishFailed={onFinishFailedThree}
          autoComplete="off"
          layout="vertical"
          form={form}
        >
          <div className="row">
            <div className="col-sm-6 labelSSS">
              <Form.Item
                name="CNIC"
                label="CNIC Number"
                rules={[
                  {
                    required: true,
                    pattern: /^[0-9]{5}-[0-9]{7}-[0-9]{1}$/,
                    message:
                      "Please enter a valid CNIC number in the format XXXXX-XXXXXXX-X",
                  },
                ]}
                normalize={(value) => {
                  if (!value) {
                    return value;
                  }

                  const onlyNums = value.replace(/[^\d]/g, "");
                  if (onlyNums.length <= 5) {
                    return onlyNums.slice(0, 5);
                  }
                  if (onlyNums.length <= 12) {
                    return `${onlyNums.slice(0, 5)}-${onlyNums.slice(5, 12)}`;
                  }
                  return `${onlyNums.slice(0, 5)}-${onlyNums.slice(
                    5,
                    12
                  )}-${onlyNums.slice(12, 13)}`;
                }}
              >
                <Input placeholder="CNIC #" />
              </Form.Item>
            </div>
            <div className="col-sm-6 labelSSS">
              <Form.Item
                name="CNIC_Expiry_Date"
                className="widthHAlf"
                label="CNIC Expiry Date"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <DatePicker placeholder="CNIC Expiry Date" />
              </Form.Item>
            </div>

            <div className="col-sm-6 labelSSS">
              <Form.Item
                // className="widthHAlf"

                name="DOB"
                label="DOB"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <DatePicker
                  placeholder="DOB"
                  disabledDate={(current) => current && current > new Date()}
                />
              </Form.Item>
            </div>

            <div className="col-sm-6 labelSSS">
              <Form.Item
                name="City"
                label="City"
                rules={[
                  {
                    required: true,
                    message: "City is required!",
                  },
                ]}
              >
                <Select
                  placeholder="City"
                  showSearch
                  filterOption={(inputValue, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(inputValue.toLowerCase()) >= 0
                  }
                >
                  {data?.map((item, i) => (
                    <Option value={item?.city_code}>{item?.city_name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            <div className="col-sm-12 labelSSS">
              <Form.Item
                name="Address"
                label="Address"
                rules={[
                  {
                    required: true,
                    message: "Address is required!",
                  },
                ]}
              >
                <Input placeholder="Address" />
              </Form.Item>
            </div>
            <div className="col-sm-6 labelSSS">
              <Form.Item
                label="Registration Number"
                name="Registration_Number"
                rules={[
                  {
                    required: true,
                    message: "Registration Number is required!",
                  },
                ]}
              >
                <Input placeholder="Registration Number" />
              </Form.Item>
            </div>
            <div className="col-sm-6 labelSSS">
              <Form.Item name="Enginer_Number" label="Engine Number">
                <Input placeholder="Engine Number" />
              </Form.Item>
            </div>
            <div className="col-sm-6 labelSSS">
              <Form.Item name="Chassis_Number" label="Chassis Number">
                <Input placeholder="Chasis Number" />
              </Form.Item>
            </div>
            <div className="col-sm-6 labelSSS">
              <Form.Item
                name="Color"
                label="Color"
                rules={[
                  {
                    required: true,
                    message: "Color is required!",
                  },
                ]}
              >
                <Select
                  placeholder="Color"
                  showSearch
                  filterOption={(inputValue, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(inputValue.toLowerCase()) >= 0
                  }
                >
                  {colorData?.map((item, i) => (
                    <Option value={item?.color_code}>{item?.color_name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className="col-sm-6 labelSSS">
              <Form.Item
                name="Engine_CC"
                label="Engine Power"
                rules={[
                  {
                    required: true,
                    message: "Please select Engine Power",
                  },
                ]}
              >
                <Select
                  placeholder="Engine CC"
                  showSearch
                  filterOption={(inputValue, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(inputValue.toLowerCase()) >= 0
                  }
                >
                  {ccData?.map((item, i) => (
                    <Option value={item?.cubic_horse_code}>
                      {item?.cubic_horse_title + " " + `(${item.type})`}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className="col-sm-6 labelSSS">
              <Form.Item
                name="Mileage"
                label="Mileage"
                rules={[
                  {
                    required: false,
                    message: "Enter mileage!",
                  },
                ]}
              >
                <Input placeholder="Mileage" />
              </Form.Item>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6 baaccck">
              <Form.Item>
                <Button onClick={handlePrev}>Back</Button>
              </Form.Item>
            </div>

            <div className="col-sm-6">
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ background: "#0FB583" }}
                >
                  Next
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default FormThree;
