import React, { Fragment } from "react";
import Fade from "react-reveal/Fade";
import FinProdNav from "./FinProdNav";
import ComputerTabs from "./ComputerTabs";
import CorporateAndFinancialForm from "../CorporateAndFinancialForms/CorporateAndFinancialForm";

const SalaamComputer = () => {
  return (
    <Fragment>
      <FinProdNav />
      <div className="container tabss motor">
        <div className="row">
          <div className="col-sm-12 heads productBanner ComputerCrimeTakaful">
            <Fade left delay={500}>
              <h2 className="oswald"> Salaam Computer Crime Takaful</h2>
              <p className="cemter">
                <b>
                  This policy provides coverage for banks against computer
                  related crimes which include:
                </b>
                <br />
                Fraudulently Altered Electronic Data, Fraudulently Altered
                Electronic Instructions & Fraudulent Electronic Transfers.
              </p>
            </Fade>
          </div>
          <div className="col-sm-12 heads text">
            <ComputerTabs />
          </div>
          <div className="col-sm-12 motorForm">
            <CorporateAndFinancialForm title={"Computer Crime"} />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SalaamComputer;
