import React, { useEffect } from "react";
import { Button, DatePicker, Form, Input, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getMotorCity } from "../../../Redux/actions/getMotorCity";
import { getTravelOccupation } from "../../../Redux/actions/getTravelOccupation";
const TravelFormThree = ({ onFinishStep3, onFinishFailedThree, handlePrev, form }) => {
  const { Option } = Select;
  const dispatch = useDispatch();
  const cityData = useSelector((state) => state.getMotorCity.data);
  const occupationData = useSelector((state) => state.getTravelOccupation.data);
  useEffect(() => {
    dispatch(getMotorCity());
    dispatch(getTravelOccupation());
  }, []);
  const genderData = [
    {
      value: "M",
      title: "Male",
    },
    {
      value: "F",
      title: "Female",
    },
  ];

  return (
    <div
      className="col-sm-8 mForm"
      style={{
        backgroundColor: "rgb(23 105 172 / 69%)",
      }}
    >
      <div className="centreWidthFOrm">
        <h3 className="cnhbsh3">Salaam Travel Takaful</h3>
        <h5 className="cnhbsh5">Personal Information</h5>
        <Form
          name="travelFormThree"
          onFinish={onFinishStep3}
          onFinishFailed={onFinishFailedThree}
          autoComplete="off"
          form={form}
          layout="vertical"
        >
          <div className="row">
            <div className="col-sm-6 labelSSS">
              <Form.Item
                name="CNIC"
                label="CNIC"
                rules={[
                  {
                    required: true,
                    pattern: /^[0-9]{5}-[0-9]{7}-[0-9]{1}$/,
                    message:
                      "Please enter a valid CNIC number in the format XXXXX-XXXXXXX-X",
                  },
                ]}
                normalize={(value) => {
                  if (!value) {
                    return value;
                  }

                  const onlyNums = value.replace(/[^\d]/g, "");
                  if (onlyNums.length <= 5) {
                    return onlyNums.slice(0, 5);
                  }
                  if (onlyNums.length <= 12) {
                    return `${onlyNums.slice(0, 5)}-${onlyNums.slice(5, 12)}`;
                  }
                  return `${onlyNums.slice(0, 5)}-${onlyNums.slice(
                    5,
                    12
                  )}-${onlyNums.slice(12, 13)}`;
                }}
              >
                <Input placeholder="CNIC #" />
              </Form.Item>
            </div>

            <div className="col-sm-6 labelSSS">
              <Form.Item
                name="CNIC_Expiry_Date"
                label="CNIC Expiry Date"
                rules={[
                  {
                    required: true,
                    message: "CNIC Expiry Date is required!"
                  },
                ]}
              >
                <DatePicker placeholder="CNIC Expiry" />
              </Form.Item>
            </div>
            <div className="col-sm-6 labelSSS">
              <Form.Item
                name="DOB"
                label="Date Of Birth"
                rules={[
                  {
                    required: true,
                    message: "Date Of Birth is required!"
                  },
                ]}
              >
                <DatePicker placeholder="DOB" disabledDate={(current) => current && current > new Date()} />
              </Form.Item>
            </div>

            <div className="col-sm-6 labelSSS">
              <Form.Item
                name="City"
                label="City"
                rules={[
                  {
                    required: true,
                    message: "City is required!"
                  },
                ]}
              >
                <Select
                  placeholder="City"
                  showSearch
                  filterOption={(inputValue, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(inputValue.toLowerCase()) >= 0
                  }
                >
                  {cityData?.map((item, i) => (
                    <Option value={item?.city_code}>{item?.city_name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            <div className="col-sm-6 labelSSS">
              <Form.Item
                name="Address"
                label="Address"
                rules={[
                  {
                    required: true,
                    message: "Address is required!"
                  },
                ]}
              >
                <Input placeholder="Address" />
              </Form.Item>
            </div>

            <div className="col-sm-6 labelSSS">
              <Form.Item
                name="Gender"
                label="Gender"
                rules={[
                  {
                    required: true,
                    message: "Please select gender!"
                  },
                ]}
              >
                <Select placeholder="Gender">
                  {genderData.map((item, i) => (
                    <Select.Option value={item.value}>
                      {item.title}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            <div className="col-sm-6 labelSSS">
              <Form.Item
                name="Occupation"
                label="Occupation"
                rules={[
                  {
                    required: true,
                    message: "Occupation is required!"
                  },
                ]}
              >
                <Select
                  placeholder="Occupation"
                  showSearch
                  filterOption={(inputValue, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(inputValue.toLowerCase()) >= 0
                  }
                >
                  {occupationData?.map((item, i) => (
                    <Option key={i} value={item?.business_code}>
                      {item?.business_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            <div className="col-sm-6 labelSSS">
              <Form.Item
                name="Passport_Number"
                label="Passport Number"
                rules={[
                  {
                    required: true,
                    pattern: /^[A-Z]{2}[0-9]{7}$/, // Adjusted pattern for Pakistani Passport format
                    message:
                      "Please enter a valid Pakistani Passport number (2 letter followed by 7 digits)",
                  },
                ]}
                normalize={(value) => {
                  if (!value) {
                    return value;
                  }

                  const onlyAlphaNumeric = value.replace(/[^A-Z0-9]/gi, ""); // Restrict to alphanumeric
                  if (onlyAlphaNumeric.length <= 2) {
                    return onlyAlphaNumeric.toUpperCase(); // Assuming the first character is a letter
                  }
                  if (onlyAlphaNumeric.length <= 9) {
                    return (
                      onlyAlphaNumeric.toUpperCase().slice(0, 2) +
                      onlyAlphaNumeric.slice(2, 9)
                    );
                  }
                  return (
                    onlyAlphaNumeric.toUpperCase().slice(0, 2) +
                    onlyAlphaNumeric.slice(2, 9)
                  );
                }}
              >
                <Input placeholder="Pakistani Passport #" />
              </Form.Item>
            </div>
          </div>


          <div className="row">
            <div className="col-sm-6 baaccck">
              <Form.Item>
                <Button onClick={handlePrev}>Back</Button>
              </Form.Item>
            </div>

            <div className="col-sm-6">
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ background: "#0FB583" }}
                >
                  Get Takaful
                </Button>
              </Form.Item>

            </div>
          </div>

        </Form>
      </div>
    </div>
  );
};

export default TravelFormThree;
