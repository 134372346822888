import React, { Fragment } from "react";
import Fade from "react-reveal/Fade";
import Flip from "react-reveal/Flip";
import Firetable from "./Firetable";
import CorProdNav from "./CorProdNav";
import SalaamFireTab from "./SalaamFireTab";
import CorporateAndFinancialForm from "../CorporateAndFinancialForms/CorporateAndFinancialForm";

const SalaamFire = () => {
  return (
    <Fragment>
      <CorProdNav />
      <div className="container tabss motor">
        <div className="row">
          <div className="col-sm-12 heads productBanner FireTakaful">
            <Fade left delay={500}>
              <h2 className="oswald"> Salaam Fire Takaful</h2>
              <p className="cemter">
                <b>
                  Fire/Property Takaful Policy designed to cover participant's
                  properties.
                </b>
                <br />
                (i.e., buildings, machinery, stock -raw material. machinery and
                other contents) whilst at participant-specified locations and
                provide coverage to the participant/firm/organizations in the
                event of loss or damage caused by perils.
              </p>
            </Fade>
          </div>

          <div className="col-sm-12 heads text">
            <SalaamFireTab />
          </div>
          <div className="col-sm-12 motorForm">
            <CorporateAndFinancialForm title={"Fire"} />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SalaamFire;
