import React, { Fragment, useState } from "react";
import Fade from "react-reveal/Fade";
import Sehattabs from "./Sehattabs";
import Whychosesehat from "../WhychoseUs/Whychosesehat";
import Productsnav from "./Productsnav";
import HealthForms from "./HealthForms/HealthForms";

function SalamSehat() {
  const [productHeading, setProductHeading] = useState();
  return (
    <Fragment>
      <Productsnav />
      <div className="container tabs motor">
        <div className="row">
          <div className="col-sm-12 heads productBanner sehat">
            <Fade left delay={500}>
              <h2 className="oswald"> Salaam Sehat Takaful</h2>
              <p className="cemter">
                <b>
                  Worried about contracting an unusual virus & ever-increasing
                  cost of healthcare facilities?
                </b>
                <br />
                <br />
                Protect your health and secure your finances with Salaam Sehat
                Takaful. In today's uncertain times, unexpected medical expenses
                can place a heavy burden on you and your family. Our
                comprehensive and affordable health coverage ensures that you
                have access to quality healthcare services whenever you need
                them, without worrying about soaring costs.{" "}
              </p>
              <br />
              <p className="cemter">
                <b>Salaam Sehat Takaful provides coverage for the following:</b>
              </p>
            </Fade>
          </div>

          <div className="col-sm-12 heads text">
            <Sehattabs setProductHeading={setProductHeading} />
          </div>

          <div className="col-sm-12 motorForm">
            <HealthForms productHeading={productHeading} />
          </div>

          <div className="col-sm-12 heads text ">
            <Whychosesehat />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default SalamSehat;
